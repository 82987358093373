<template>
  <div class="head fixed" :class="{ 'one-page-head': isOnePage }">
    <div class="content">
      <div class="logo">
        <img :src="logo" alt="LOGO" v-if="logo" />
        <span v-else>LOGO</span>
      </div>
      <div class="nav" v-if="!isOnePage">
        <a-menu
          class="nav-content"
          mode="horizontal"
          v-model:selectedKeys="currentMenu"
          v-if="menusOk"
        >
          <a-menu-item v-for="item in menus" :key="item.path">
            <router-link class="nav-item" :to="item.path" v-if="item.menuCode">
              <template v-if="item.menuCode == 'home'">{{
                $t(item.menuName)
              }}</template>
              <template v-else>{{
                item.langNamesObj
                  ? item.langNamesObj[langNamesEnum[languageActive]]
                  : item.menuName
              }}</template>
              <span class="label new" v-if="item.labelId == 1">
                <i
                  >{{ $t("CM_Newest")
                  }}<!-- 最新 --></i
                >
              </span>
              <span class="label hot" v-if="item.labelId == 2">
                <i
                  >{{ $t("hottest")
                  }}<!-- 最热 --></i
                >
              </span>
            </router-link>
            <a class="nav-item" target="_blank" :href="item.path" v-else
              >{{
                item.langNamesObj
                  ? item.langNamesObj[langNamesEnum[languageActive]]
                  : item.menuName
              }}
              <span class="label new" v-if="item.labelId == 1">
                <i
                  >{{ $t("CM_Newest")
                  }}<!-- 最新 --></i
                >
              </span>
              <span class="label hot" v-if="item.labelId == 2">
                <i
                  >{{ $t("hottest")
                  }}<!-- 最热 --></i
                >
              </span>
            </a>
          </a-menu-item>
        </a-menu>
      </div>
      <div class="info">
        <div class="search-icon" @click="showSearch = !showSearch">
          <SearchOutlined v-show="!showSearch" />
          <CloseOutlined v-show="showSearch" />
        </div>
        <a
          class="admin"
          v-if="adminLink"
          :href="`${adminLink}${newWindow == 1 ? '?ddtab=true' : ''}`"
          :target="newWindow == 1 ? '_blank' : '_self'"
        >
          <DesktopOutlined style="font-size: 24px" />
          <span>{{ $t("Head.Admin") }}</span>
        </a>
        <div class="down" v-if="isOpenDownloadWatermark" @click="toDown">
          <DownloadOutlined style="font-size: 24px" />
        </div>
        <div class="bell" @click="toMsg">
          <a-badge
            :offset="[9, -1]"
            :numberStyle="{ height: '17px', lineHeight: '17px' }"
            :count="msgs"
          >
            <BellOutlined style="font-size: 24px" />
          </a-badge>
        </div>
        <div class="user">
          <div class="avatar" v-if="user" @click="toMine('profile')">
            <a-avatar :size="40" :src="user.portrait" />
          </div>
          <div class="name" v-if="user" @click="toMine('profile')">
            <OpenData type="userName" :openid="user.realName" />
          </div>
          <div class="user-box">
            <ul>
              <li @click.stop="toMine('profile')">
                <UserOutlined /><i>{{ $t("Head.Mine") }}</i>
              </li>
              <li
                @click.stop="toTeacher"
                v-if="isTeacher == 1 || isTeacher == 2"
              >
                <SolutionOutlined /><i>{{ $t("Head.Lecturer") }}</i>
              </li>
              <li class="sm-menu" v-if="languageLabels.length > 1">
                <GlobalOutlined /><i>{{ $t("Head.SelecLanguage") }}</i>
                <RightOutlined style="margin: 0 0 0 16px" />
                <ul class="sub-menu">
                  <li
                    @click.stop="langChange(item)"
                    v-for="item in languageLabels"
                    :key="item.value"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </li>
              <li @click.stop="toLogout">
                <PoweroffOutlined /><i>{{ $t("Head.Logout") }}</i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- <div v-if="showSearch" class="search-content">
        <Search @search="showSearch = false" />
      </div> -->
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, computed, createVNode, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import ls from "@/utils/local-storage";
import {
  userInfo,
  msgCount,
  logout,
  companyInfo,
  wxworkConfig,
  switchunit,
  isLecturer,
  homeConfig,
} from "@/api/user";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import { ToLogin } from "@/utils/request.js";
import { platformConfig, switchlanguage } from "@/api/other";
import { currentHost } from "@/utils/request.js";
import { upFavicon, getQueryVariable, clearStorage } from "@/utils/tools";
import { menusTitleSet } from "@/utils/business";
import OpenData from "@/components/OpenData.vue";
import { setLang } from "@/utils/tools";
//import Search from "./Search.vue";

export default {
  name: "NewHead",
  components: {
    OpenData,
   // Search,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const languageLabels = ref([]);
    const languageIndex = {
      "zh-CN": "简体中文",
      "zh-TW": "繁体中文",
      "en-US": "English",
    };
    const langNamesEnum = {
      "zh-CN": "zh_CN",
      "zh-TW": "zh_TW",
      "en-US": "en_US",
    };
    const langLoading = ref(false);
    const languageActive = computed(() => store.getters.lang);
    const langChange = (item) => {
      if (item.value == languageActive.value) return false;
      // langLoading.value = true;
      proxy.$i18n.locale = item.value;
      store.dispatch("setLang", item.value);
      location.reload();
      // let type = "";
      // if (item.value == "zh-CN") {
      //   type = "zh_CN";
      // } else if (item.value == "zh-TW") {
      //   type = "zh_TW";
      // } else if (item.value == "en-US") {
      //   type = "en_US";
      // }
      // switchlanguage({ lang: type }).then((res) => {
      //   if (res.ret == 0) {
      //     store.dispatch("setToken", res.data.token);
      //     let toLoginTime = parseInt(res.data.expiresIn / 60 - 30);
      //     let curTime = new Date();
      //     let expiredate = new Date(
      //       curTime.setMinutes(curTime.getMinutes() + Number(toLoginTime))
      //     );
      //     ls.set("TokenExpire", JSON.stringify(Date.parse(expiredate))); //多少分钟后过期！
      //     ls.set("refreshToken", res.data.refreshToken);
      //     langLoading.value = false;
      //     location.reload();
      //   }
      // });
    };

    // 导航
    let nowPath = computed(() => route.path);
    const menusOk = ref(false);
    const menus = ref([
      {
        menuName: "Head.Home",
        menuCode: "home",
        path: "/",
        activePath: "/",
      },
    ]);

    const currentMenu = computed(() => [route.path]);
    homeConfig().then((res) => {
      if (res.ret == 0 && res.data.data.length > 0) {
        let allMenus = [];
        let menusTitle = {};
        res.data.data[0].dataList.MenuList.forEach((item) => {
          if (item.langNames) {
            item.langNames = JSON.parse(item.langNames);
            item.langNamesObj = {};
            item.langNames.forEach((item2) => {
              item.langNamesObj[item2.key] = item2.value;
            });
          }
          if (item.menuCode) {
            menusTitle[item.menuCode] = item.langNamesObj || item.menuName;
          }
          if (item.checked) {
            if (item.menuCode) {
              if (item.menuCode === "center") {
                item.path = "/mine";
                item.activePath = "/mine";
              } else if (item.menuCode === "plan") {
                item.path = "/project";
                item.activePath = "/project";
              } else if (item.menuCode === "community") {
                item.path = "/bbs";
                item.activePath = "/bbs";
              } else {
                item.path = "/" + item.menuCode;
                item.activePath = "/" + item.menuCode;
              }
            } else {
              item.path = item.url;
            }
            allMenus.push(item);
          }
        });
        ls.set("menusTitle", menusTitle);
        menusTitleSet(route.path);

        menus.value = menus.value.concat(allMenus);
        menusOk.value = true;
      }
    });

    // 获取用户信息
    let user = computed(() => store.getters.userInfo);
    let units = ref([]);
    userInfo().then((res) => {
      if (res.ret === 0) {
        units.value = res.data.units;
        store.dispatch("setUserInfo", res.data);
      }
    });

    // 是否是讲师
    const isTeacher = ref(4);
    isLecturer().then((res) => {
      if (res.ret === 0) {
        // 1 是讲师&申请 2 是讲师&后台添加 3 非讲师&有记录 4 非讲师&没记录
        isTeacher.value = res.data;
      }
    });

    // 获取企业信息
    let logo = ref("");
    const isOnePage = ref(false); // 是否单页版本
    const isOpenDownloadWatermark = ref(false); // 是否开启下载水印
    companyInfo().then((res) => {
      if (res.ret == 0) {
        logo.value = res.data.logo1;
        isOnePage.value = [1, 2, 3].includes(res.data.mobileModule);
        isOpenDownloadWatermark.value = res.data.isOpenDownloadWatermark == 1;
        if (res.data.logo2) upFavicon(res.data.logo2);
        if (res.data.theme)
          document.body.style.setProperty("--theme", res.data.theme);
        store.dispatch("setCompanyInfo", res.data);
        let languagesArr = res.data.languages.split(",");
        languageLabels.value = [];
        if (languagesArr.length) {
          languagesArr.forEach((item, index) => {
            if (item == "zh_CN") {
              languageLabels.value.push({
                name: "简体中文",
                value: "zh-CN",
                icon: "🇨🇳",
              });
            } else if (item == "zh_TW") {
              languageLabels.value.push({
                name: "繁体中文",
                value: "zh-TW",
                icon: "🇭🇰",
              });
            } else if (item == "en_US") {
              languageLabels.value.push({
                name: "English",
                value: "en-US",
                icon: "🇺🇸",
              });
            }
          });
        } else {
          languageLabels.value.push({
            name: "简体中文",
            value: "zh-CN",
            icon: "🇨🇳",
          });
        }
        setLang(res.data.languages);
      }
    });

    // 获取消息数 
    let msgs = computed(() => store.getters.msgCount);
    msgCount().then((res) => {
      if (res.ret === 0) {
        store.dispatch("setMsgCount", res.data);
      }
    });

    let adminLink = ref("");
    adminLink.value = ls.get("adminLink");

    let showLogout = ref(false);
    showLogout.value = ls.get("platform") === "web";

    const toMine = (currentView) => {
      router.push("/mine#" + currentView);
    };

    const toMsg = () => {
      router.push({
        path: "/mine/msg",
      });
    };

    const toDown = () => {
      router.push({
        path: "/mine/down",
      });
    };

    const toTeacher = () => {
      router.push({
        path: "/teacher",
      });
    };

    const toLogout = () => {
      Modal.confirm({
        title: () => $t("login.logout_tip"),
        // 确定要退出登录吗？
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          logout().then((res) => {
            ToLogin('/', true);
          });
        },
      });
    };

    const switchUnit = (item) => {
      switchunit(item.id).then((res) => {
        window.location.href =
          location.protocol +
          "//" +
          item.studyDomain +
          "/login/switch?key=" +
          res.data.key;
      });
    };

    const ua =
      navigator && navigator.userAgent
        ? navigator.userAgent.toLowerCase() || ""
        : "";
    const isWxWork = /wxwork/i.test(ua); // 是否企业微信
    const isDingding = /dingtalk/i.test(ua); // 是否钉钉

    const platformConfig2 = computed(() => store.getters.platformConfig);
    let ddInitEd = false;
    if (platformConfig2.value.platform == 31 && isDingding) {
      let dingCorpid = getQueryVariable("corpid");
      let lsDingCorpid = ls.get("dingCorpid");
      if (dingCorpid) {
        if (lsDingCorpid && dingCorpid != lsDingCorpid) {
          clearStorage();
        } else {
          dingCorpid = lsDingCorpid;
        }
      } else {
        dingCorpid = lsDingCorpid;
      }
      console.log("ddInit-1");
      let ddInit = undefined;
      try {
        ddInit = window.DTOpenData.init(dingCorpid);
        console.log("ddInit-1:" + ddInit);
        setTimeout(() => {
          window.DTOpenData.update(document.querySelectorAll("dt-open-data"));
        }, 500);
      } catch (e) {
        ddInit = true;
      }
      ddInitEd = true;
      if (!ddInit) {
        let href =
          "https://login.dingtalk.com/oauth2/auth?response_type=code&client_id=dingwa4tibze6jwz7mgv&scope=openid&state=dddd&redirect_uri=" +
          encodeURIComponent(
            "http://auth.dingtalk.com/login?redirectUri=" +
              encodeURIComponent(location.href)
          );
        window.location.href = href;
      }
    }

    platformConfig({ site: currentHost }).then((res) => {
      if (res.data.theme) {
        document.body.style.setProperty("--theme", res.data.theme);
      }
      if (res.data.platform == 26) {
        if (isWxWork) {
          console.log("isWxWork");
          wxworkConfig({
            url:
              location.protocol +
              "//" +
              location.hostname +
              location.pathname +
              location.search,
            jsTicketType: "2", // 不为空 - 获取企业标识 为空 则为应用标识
          }).then((r) => {
            let d = r.data;
            wx.config({
              beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              //debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: d.corpId, // 必填，公众号的唯一标识
              timestamp: d.timestamp, // 必填，生成签名的时间戳
              nonceStr: d.nonceStr, // 必填，生成签名的随机串
              signature: d.signature, // 必填，签名，见附录1
              jsApiList: [
                "checkJsApi",
                "startLiving",
                "replayLiving",
                "agentConfig",
                "invoke",
                "startMeeting",
              ],
              openTagList: ["wx-open-launch-app"],
            });
            wx.ready(() => {
              wxworkConfig({
                url:
                  location.protocol +
                  "//" +
                  location.hostname +
                  location.pathname +
                  location.port +
                  location.search,
              }).then((res) => {
                let d2 = res.data;
                ls.set("wxworkCorpid", d.corpId);
                console.log("wx");
                wx.agentConfig({
                  debug: true,
                  corpid: d2.corpId,
                  agentid: d2.agentid,
                  timestamp: d2.timestamp,
                  nonceStr: d2.nonceStr,
                  signature: d2.signature,
                  jsApiList: ["selectExternalContact"],
                  success: function (res) {
                    // 回调
                    console.log("agentConfigSuccess：" + JSON.stringify(res));
                  },
                  fail: function (res) {
                    if (res.errMsg.indexOf("function not exist") > -1) {
                      alert("版本过低请升级");
                    }
                  },
                });
              });
            });
            wx.error((res) => {
              console.log(res, "---");
            });
          });
        } else {
          wxworkConfig({
            url:
              location.protocol +
              "//" +
              location.hostname +
              location.pathname +
              location.port +
              location.search,
          }).then((res) => {
            let d = res.data;
            ls.set("wxworkCorpid", d.corpId);
            console.log("wx");
            wx.agentConfig({
              debug: true,
              corpid: d.corpId,
              agentid: d.agentid,
              timestamp: d.timestamp,
              nonceStr: d.nonceStr,
              signature: d.signature,
              jsApiList: ["selectExternalContact"],
              success: function (res) {
                // 回调
                console.log("agentConfigSuccess：" + JSON.stringify(res));
              },
              fail: function (res) {
                if (res.errMsg.indexOf("function not exist") > -1) {
                  alert("版本过低请升级");
                }
              },
            });
          });
        }
      } else if (res.data.platform == 31 && isDingding && !ddInitEd) {
        let dingCorpid = ls.get("dingCorpid");
        console.log("ddInit-2");
        let ddInit = undefined;
        try {
          ddInit = window.DTOpenData.init(dingCorpid);
          console.log("ddInit-2:" + ddInit);
          setTimeout(() => {
            window.DTOpenData.update(document.querySelectorAll("dt-open-data"));
          }, 500);
        } catch (e) {
          ddInit = true;
        }
        if (!ddInit) {
          let href =
            "https://login.dingtalk.com/oauth2/auth?response_type=code&client_id=dingwa4tibze6jwz7mgv&scope=openid&state=dddd&redirect_uri=" +
            encodeURIComponent(
              "http://auth.dingtalk.com/login?redirectUri=" +
                encodeURIComponent(location.href)
            );
          window.location.href = href;
        }
      }
    });

    // 企业微信 微信 进入学员端 屏蔽 后台管理 退出登录
    const isWeb = computed(() => store.getters.platform === "web");

    const showSearch = ref(false);

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      languageLabels,
      languageIndex,
      langNamesEnum,
      languageActive,
      langChange,
      langLoading,
      logo,
      nowPath,
      menus,
      menusOk,
      currentMenu,
      user,
      units,
      adminLink,
      isTeacher,
      showLogout,
      msgs,
      toMine,
      toMsg,
      toDown,
      toLogout,
      toTeacher,
      switchUnit,
      isWeb,
      isWxWork,
      isDingding,
      isOnePage,
      showSearch,
      isOpenDownloadWatermark,
    };
  },
};
</script>

<style lang="less">
.ant-modal-confirm-btns .ant-btn-primary {
  background: var(--theme) !important;
  border-color: var(--theme) !important;
}
.ant-modal-confirm-btns .ant-btn:hover,
.ant-modal-confirm-btns .ant-btn:focus {
  color: var(--theme) !important;
  border-color: var(--theme) !important;
}
.ant-modal-confirm-btns .ant-btn-primary:hover,
.ant-modal-confirm-btns .ant-btn-primary:focus {
  color: #fff !important;
}
.ant-menu-submenu-popup {
  .ant-menu {
    min-width: 158px !important;
    max-height: unset !important;
    padding: 14px 0 !important;
    border-radius: 8px !important;
    box-shadow: 0 0 20px 0 rgba(148, 148, 148, 0.1) !important;
    .ant-menu-item {
      padding: 8px 20px;
      height: 36px;
      line-height: 20px;
      margin-top: 0;
      margin-bottom: 0;
      a {
        color: #333;
        font-size: 16px;
        font-weight: 600;
      }
      &:hover {
        a {
          color: @color-theme;
        }
      }
      &.ant-menu-item-selected {
        background-color: #fff;
        a {
          color: @color-theme;
        }
      }
      .nav-item {
        .label {
          display: none;
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
.head {
  height: 84px;
  background-color: #ffffff;
  z-index: 999;
  .content {
    position: relative;
    .mixinWrap();
    .mixinFlex(space-between);
    padding: 20px 0;
    .logo {
      .mixinImgWrap(181px; 44px);
      span {
        font-size: 18px;
        letter-spacing: 4px;
        .mixinFlex(center; center);
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
      }
    }
    .nav {
      width: calc(100% - 540px);
      &-content {
        border-bottom: none;
        ::v-deep(.ant-menu-item) {
          padding: 0 20px;
          &::after {
            bottom: -4px;
            left: calc(50% - 16px);
            width: 32px;
            border-bottom: 6px solid transparent;
            border-radius: 6px;
          }
          a {
            color: #333;
            font-size: 16px;
            font-weight: 600;
            &:hover {
              color: @color-theme;
            }
          }
          .label {
            position: absolute;
            top: -2px;
            right: -2px;
            padding: 0 4px;
            height: 16px;
            border-radius: 12px;
            color: #fff;
            font-size: 12px;
            font-weight: normal;
            text-align: center;
            line-height: 16px;
            &.new {
              background-color: #44b44f;
            }
            &.hot {
              background-color: #e8673e;
            }
            i {
              display: inline-block;
              transform: scale(0.8);
            }
          }
          .ant-menu-submenu-title .anticon {
            font-size: 16px;
          }
          &:hover {
            &::after {
              border-bottom: 6px solid transparent;
            }
          }
          &.ant-menu-item-active {
            &::after {
              border-bottom: 6px solid transparent;
            }
          }
          &.ant-menu-item-selected {
            a {
              color: @color-theme;
            }
            &::after {
              border-bottom: 6px solid @color-theme;
            }
          }
        }
        ::v-deep(.ant-menu-submenu) {
          &::after {
            bottom: -4px;
            left: calc(50% - 16px);
            width: 32px;
            border-bottom: 6px solid transparent;
            border-radius: 6px;
          }
          &:hover {
            &::after {
              border-bottom: 6px solid transparent;
            }
          }
          &:hover {
            color: @color-theme;
          }
        }
        ::v-deep(.ant-menu-submenu-title) {
          &:hover {
            color: @color-theme;
          }
          .anticon-ellipsis {
            font-size: 25px !important;
            transform: translateY(5px);
          }
        }
        ::v-deep(.ant-menu-submenu-selected) {
          color: @color-theme;
        }
      }
    }
    .info {
      font-size: 14px;
      color: #333;
      line-height: 20px;
      .mixinFlex(flex-end; center);
      .search-icon {
        height: 24px;
        font-size: 24px;
        cursor: pointer;
        &:hover {
          color: var(--theme);
        }
      }
      .admin {
        .mixinFlex(center; center);
        margin-left: 20px;
        span {
          margin-left: 8px;
        }
      }
      .down {
        margin-left: 20px;
        cursor: pointer;
        &:hover {
          .ant-badge {
            color: @color-theme;
          }
        }
      }
      .bell {
        margin-left: 20px;
        cursor: pointer;
        &:hover {
          .ant-badge {
            color: @color-theme;
          }
        }
      }
      .user {
        .mixinFlex(flex-end; center);
        margin-left: 32px;
        position: relative;
        cursor: pointer;
        .name {
          margin-left: 12px;
        }
        .user-box {
          z-index: 10000;
          position: absolute;
          right: -54px;
          top: 40px;
          padding-top: 10px;
          opacity: 0;
          visibility: hidden;
          transition: all 0.2s;
          transform: scaleY(0.8);
          transform-origin: 0 0;
          ul {
            width: 158px;
            background-color: #fff;
            box-shadow: 0 0 20px 0 rgba(148, 148, 148, 0.1);
            border-radius: 4px;
            padding: 14px 0;
            li {
              height: 32px;
              padding: 6px 20px;
              font-size: 14px;
              color: #666;
              .mixinFlex(flex-start; center);
              cursor: pointer;
              span {
                margin-right: 12px;
                font-size: 16px;
              }
              i {
                font-style: normal;
              }
              &:hover {
                color: @color-theme;
              }
              &.sm-menu {
                position: relative;
                .sub-menu {
                  width: 118px;
                  position: absolute;
                  left: 158px;
                  top: -46px;
                  opacity: 0;
                  visibility: hidden;
                  transform: scaleY(0.8);
                  transform-origin: 0 0;
                  transition: all 0.3s;
                }
                &:hover {
                  .sub-menu {
                    opacity: 1;
                    transform: scaleY(1);
                    visibility: initial;
                  }
                }
              }
            }
          }
        }
        &:hover {
          .user-box {
            opacity: 1;
            transform: scaleY(1);
            visibility: initial;
          }
        }
      }
    }
    .search-content {
      position: absolute;
      top: 84px;
      left: 0;
      right: 0;
      z-index: 111;
    }
  }
}
</style>
